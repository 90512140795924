import axios from "axios";
//import { serverUrl } from '../config.json';
const serverUrl = require("../config").get(process.env.NODE_ENV).serverUrl;

const UNAUTHORIZED = 401;
const axiosInstance = axios;

axiosInstance.defaults.headers.common = {
  "Access-Control-Allow-Origin": "*"
};

axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    console.log(error);

    if (error && error.response) {
      const { status } = error.response;
      if (status === UNAUTHORIZED) {
        
        window.location.replace(`${serverUrl}/login`)

      }
    }
    return Promise.reject(error);
  }
);

export default {
  get: axiosInstance.get,
  post: axiosInstance.post,
  put: axiosInstance.put,
  delete: axiosInstance.delete
};
