import React, { useState, useEffect, useMemo, Fragment } from "react";
import { getProducts, getGroups, getCurrecies, getProductsReportList } from "../service/productService";
import { getIsValidUser } from "../service/autService";
import { updateProductCurrencyList, resetProductCurrencyValue, resetAllProductCurrencyValue } from "../service/productCurrencyService";
import { getLatestRate, searchReportData, getLatestDate, getServiceProvider, getLiveRates } from "../service/reportService";
import { Button, Modal, InputGroup, FormControl, Dropdown, Form, Spinner, Tooltip, OverlayTrigger } from 'react-bootstrap';
//import moment from "moment";

import { Col, Container, Row } from "reactstrap";

var moment = require('moment-timezone');

export default function Report() {

    const initialSearchState = {
        productId: 0,
        currencyId: 0
    };

    // const [reportProduct, setReportProduct] = useState(initialProductState);
    const [products, setProducts] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [productsReport, setProductReport] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [snapShotDate, setSnapShotDate] = useState([]);
    const [serviceProvider, setServiceProvider] = useState([]);
    const [searchState, setSearchState] = useState(initialSearchState);
    const [clickTimeout, setclickTimeout] = useState(null);
    const [isAdmin, setisAdmin] = useState(false);
    const [editList, seteditList] = useState([]);
    const [selectedValue, setselectedValue] = useState(null);
    const [liveRates, setLiveRates] = useState([]);
    const [filterLiveRates, setFilterLiveRates] = useState([]);
    const [fromCurrency, SetfromCurrency] = useState("");
    const [toCurrency, SettoCurrency] = useState("");
    const [showLiveRate, SetshowLiveRate] = useState(false);
    const [isClinetPortalView, setIsClinetPortalView] = useState(false);

    const populateLiveRates = (data, from, to) => {
        console.log(data);
        var filter = data.filter(r => (from === '' || r.fromCurrencyCode === from) &&
            (to === '' || r.toCurrencyCode === to))
        setFilterLiveRates(filter);
    }



    useEffect(() => {

        getCureencyData();
        getReportProduct();
        searchReprot();
        getLatestSnapDate();
        getServiceRecordProvider();

    }, []);

    const loginUrl = require("../config").get(process.env.NODE_ENV).loginUrl;


    const validateUser = (isViewonly) => {
        if (!isViewonly) {
            getIsValidUser().then(res => {
                if (res.data == true) {
                    setisAdmin(true);
                }
                else {
                    window.location.replace(loginUrl)

                }
            }).catch(err => {
                window.location.replace(loginUrl)

            });
        }
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSearchState({ ...searchState, [name]: value });
    };

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const isViewonly = params.get('view');
        let isClinetPortalView = params.get('clientView');
        if (isClinetPortalView) {
            setIsClinetPortalView(true);
        }

        console.log("params===============", isViewonly)
        if (isViewonly != null && isViewonly) {
            setisAdmin(false);
        }
        else {
            validateUser(isViewonly);
        }
        searchReprot();
    }, [searchState]);

    function useWindowSize() {
        // Initialize state with undefined width/height so server and client renders match
        // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
        const [windowSize, setWindowSize] = useState({
            width: undefined,
            height: undefined,
        });

        useEffect(() => {
            // Handler to call on window resize
            function handleResize() {
                // Set window width/height to state
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }

            // Add event listener
            window.addEventListener("resize", handleResize);

            // Call handler right away so state gets updated with initial window size
            handleResize();

            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }, []); // Empty array ensures that effect is only run on mount

        return windowSize;
    }



    const searchReprot = () => {
        setReportData([])
        searchReportData(searchState.productId, searchState.currencyId).then(res => {
            setReportData(res.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const getlatestRates = () => {
        getLatestRate().then(res => {
            getReportProduct();
            alert("Rate update process complete.");
            getLatestSnapDate();
            searchReprot();
        }).catch(err => {
            console.log(err);
        });

        alert("Rate update process runing please wait.");
    }

    const onResetAllClick = () => {
        if (window.confirm('This will overwrite all the rates with CE live rate, are you sure want to continue?')) {
            resetAllProductCurrencyValue().then(res => {
                alert("Successfully Updated.");
                searchReprot();
            }).catch(err => {
                console.log(err);
            });
        }

    }

    // const getProductData = () => {
    //     getProducts().then(res => {
    //         setProducts(res.data);
    //     }).catch(err => {
    //         setProducts([])
    //     });
    // }
    const getCureencyData = () => {
        getCurrecies().then(res => {
            setCurrencies(res.data);
            console.log("======== currency data====", res);
        }).catch(err => {
            console.log(err);
        });
    }

    const getLatestSnapDate = () => {
        getLatestDate().then(res => {
            console.log("============ get snap shot date=====", res)
            setSnapShotDate(res.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const getReportProduct = () => {
        getProductsReportList().then(res => {
            setProductReport(res.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const getServiceRecordProvider = () => {
        getServiceProvider().then(res => {
            console.log("============ set ServiceProvider =====", res)
            setServiceProvider(res.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const onShowLiveRate = () => {
        getLiveRates().then(res => {
            setLiveRates(res.data);
            populateLiveRates(res.data, "", "");
            SetshowLiveRate(true);
        }).catch(err => {
            console.log(err);
        });
    }

    // const setDateFormat = () => {
    //     moment(snapShotDate).format('MMMM Do YYYY, h:mm:ss a');
    // }

    const size = useWindowSize();

    const createTable = () => {

        const styles = ({
            container: {
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',

            },
            groupContainer: {
                backgroundColor: '#3385ff',
                display: 'flex',
                justifyContent: 'center',
                height: size.height * 1 / 17,
            },
            groupContainerPadding: {
                padding: 10
            },
            groupProductContainer: {
                display: 'flex',
                flexDirection: 'row'
            },
            groupproductFlex: {
                flex: 1
            },
            productCintainer: {
                backgroundColor: '#87A7FF',
                display: 'flex',
                alignItems: 'center',
                height: 65,
                marginBottom: 20
            },
            productContainerPadding: {
                padding: 8
            },
            valuesContainerPadding: {
                padding: 10,
                alignItems: 'right',
                justifyContent: 'right'
            },
        });

        var products = [{
            id: 1,
            name: "Item name 1",
            price: 100
        }, {
            id: 2,
            name: "Item name 2",
            price: 100
        }];

        const columns = [{
            dataField: 'id',
            text: 'Product ID'
        }, {
            dataField: 'name',
            text: 'Product Name'
        }, {
            dataField: 'price',
            text: 'Product Price'
        }];


        // It's a data format example.
        function priceFormatter(cell, row) {
            return '<i class="glyphicon glyphicon-usd"></i> ' + cell;
        }

        const handleClicks = (evt) => {
            if (clickTimeout !== null) {
                console.log('double click executes')
                clearTimeout(clickTimeout)
                setclickTimeout(null)
            } else {
                console.log('single click')
                setclickTimeout(setTimeout(() => {
                    console.log('first click executes ')
                    clearTimeout(clickTimeout)
                    setclickTimeout(null)
                }, 2000))
            }
        }

        const handleClick = (evt, object) => {

            setselectedValue(object);
        }

        const handleInputEditChange = (event, report, groupProduct, valueIndex, groupProductIndex, reportIndex) => {
            const { name, value } = event.target;
            let newReportData = [...reportData]
            let newValues = [...groupProduct.values]

            newValues[valueIndex] = { ...newValues[valueIndex], value: value, isEdit: true }
            groupProduct.values = newValues;
            report.groupProducts[groupProductIndex] = groupProduct;
            newReportData[reportIndex] = report;

            console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
            console.log(newValues);



            setReportData(newReportData);
            let index = -1;
            if (newValues[valueIndex].productCurrency) {
                index = editList.findIndex(element => element !== undefined && element.productCurrency !== undefined &&
                    element !== null && element.productCurrency !== null
                    && element.productCurrency.id === newValues[valueIndex].productCurrency.id &&
                    element.productCurrency.toCurrencyId === newValues[valueIndex].productCurrency.toCurrencyId)
            }

            if (index === -1) {
                editList.push(newValues[valueIndex])
            }
            else {
                editList[index].value = value
            }

        }

        const onSaveClick = () => {
            updateProductCurrencyList(editList).then(res => {
                seteditList([]);
                alert("Successfully Updated.");
                searchReprot();
            }).catch(err => {
                console.log(err);
            });
        }

        const onResetClick = () => {
            console.log(selectedValue);
            if (selectedValue !== null && selectedValue.isManualOverride) {
                if (window.confirm('Do you want to reset to the Live rate?')) {
                    resetProductCurrencyValue(selectedValue).then(res => {
                        alert("Successfully Updated.");
                        searchReprot();
                    }).catch(err => {
                        console.log(err);
                    });
                }
            }
        }

        const showColumn = (colName) => {
            console.log(isClinetPortalView, colName);
            if (isClinetPortalView && colName != "TT" && colName != "Ceycash" && colName != "Bank Transfer" && colName != "Cash Pickup" && colName != "Cash Pickup Service" && colName !="Currency" ) {
                // only show TT and Ceycash for clint view
                return false;
            } else {
                return true;
            }
        }




        const tableGenerator = () => {
            return (
                <table className="table table-hover">
                    <thead>
                        <tr style={{ backgroundColor: "#fff", position: 'sticky', top: "0" }}>
                            {reportData.length > 0 && reportData.map && reportData.map((report, i) => (
                                <Fragment key={i}>
                                    {
                                        report.groupProducts.map((groupProduc, index) => {
                                            return (
                                                groupProduc.colName === "Live Rate" ?
                                                    isAdmin ?
                                                        <th key={i + groupProduc.colName} style={{ backgroundColor: "#fff", position: 'sticky', top: "0" }}>

                                                            <OverlayTrigger
                                                                key={i}
                                                                placement={"bottom"}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-${i}`}>
                                                                        {groupProduc.toolTip || groupProduc.colName}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <div title={groupProduc.toolTip} style={groupProduc.colName !== "Currency" ? { textAlign: 'right' } : null}
                                                                    data-toggle="popover" title="Popover title" data-content="And here's some amazing content. It's very engaging. Right?">
                                                                    {groupProduc.colName}
                                                                </div>
                                                            </OverlayTrigger>


                                                        </th> : null :

                                                    showColumn(groupProduc.colName) ? <th key={i + groupProduc.colName} style={{ backgroundColor: "#fff", position: 'sticky', top: "0" }}>
                                                        <OverlayTrigger
                                                            key={i}
                                                            placement={"bottom"}
                                                            overlay={
                                                                <Tooltip id={`tooltip-${i}`}>
                                                                    {groupProduc.toolTip || groupProduc.colName}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <div title={groupProduc.toolTip} style={groupProduc.colName !== "Currency" ? { textAlign: 'right' } : null}
                                                            >
                                                                {groupProduc.colName}
                                                            </div>
                                                        </OverlayTrigger>

                                                    </th> : null
                                            )
                                        }
                                        )}
                                </Fragment>)
                            )}
                        </tr>
                    </thead>

                    <tbody>
                        {reportData.length > 0 && reportData[0].groupProducts[0].values.map((a, index) => {
                            return (
                                <tr key={index}>
                                    {reportData.map((report, ri) => (
                                        <Fragment key={ri}>
                                            {
                                                report.groupProducts.map((groupProduc, j) => {
                                                    return (
                                                        j === 0 && groupProduc.colName === "Currency" ?
                                                            <td key={j}>
                                                                <div>
                                                                    <img width="24" alt="flag" src={require('../asset/images/flags/' + groupProduc.values[index].value.toLowerCase() + '.png')}></img>
                                                                    {" "}
                                                                    {groupProduc.values[index].value}
                                                                </div>
                                                            </td>
                                                            :

                                                            !isAdmin && groupProduc.colName === "Live Rate" ?
                                                                null
                                                                :

                                                                showColumn(groupProduc.colName) ? <td key={j}>
                                                                    {
                                                                        groupProduc.values[index].value !== null ?
                                                                            !isAdmin ?
                                                                                <div style={{ textAlign: 'right' }} data-toggle="tooltip" data-placement="top" title={isAdmin === true && groupProduc.values[index].toolTip}>
                                                                                    {groupProduc.values[index].value}
                                                                                </div>
                                                                                :
                                                                                groupProduc.colName === "Live Rate" ?
                                                                                    <div style={{ textAlign: 'right' }} data-toggle="tooltip" data-placement="top" title={isAdmin === true && groupProduc.values[index].toolTip}>
                                                                                        {groupProduc.values[index].value}
                                                                                    </div> :
                                                                                    <form>

                                                                                        <Form.Control style={groupProduc.values[index].isEdit ? { minWidth: 110, background: 'yellow', textAlign: 'right' } :
                                                                                            groupProduc.values[index].isManualOverride ? { minWidth: 110, background: 'lavender', textAlign: 'right' } :
                                                                                                { minWidth: 110, textAlign: 'right' }}
                                                                                            id={index} type="number" step="0.0001"
                                                                                            value={groupProduc.values[index].value}
                                                                                            onClick={(e) => handleClick(e, groupProduc.values[index])}
                                                                                            onChange={(event) => handleInputEditChange(event, report, groupProduc, index, j, ri)} />
                                                                                        <div> {groupProduc.values[index].isManualOverride}</div>
                                                                                    </form>
                                                                            : null
                                                                    }
                                                                </td> : null
                                                    )
                                                }
                                                )}
                                        </Fragment>)
                                    )}
                                </tr>)
                        })
                        }
                    </tbody>
                </table >
            );
        }
        return (
            <div>
                {/* {isAdmin &&
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <h6 className="float-right">Last Updated: {moment.utc(snapShotDate).tz("Australia/Sydney").format('DD MMM YYYY, h:mm a')}</h6>
                    </div>
                } */}
                <div>
                    <br />

                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', flex: 1 }}>
                        {isAdmin &&
                            <div style={{ width: '35%' }}>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>Select Product</Form.Label>
                                    <Form.Control as="select" onChange={handleInputChange} name="productId">
                                        <option value="0">Select a Product</option>
                                        {productsReport !== null && productsReport.map(reportProduct => (
                                            <option
                                                key={reportProduct.key}
                                                value={reportProduct.key}
                                            >
                                                {reportProduct.value}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </div>
                        }
                        <div style={{ width: '35%' }}>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Select Currency</Form.Label>
                                <Form.Control as="select" onChange={handleInputChange} name="currencyId">
                                    <option value="0">Select a Currency</option>
                                    {currencies.map(currency => (
                                        <option
                                            key={currency.key}
                                            value={currency.key}
                                        >
                                            {currency.value}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div>
                            <Form >
                                <Button type="button" onClick={searchReprot} style={{ marginTop: 30 }}>Search</Button>
                            </Form>
                        </div>
                    </div>
                    {isAdmin ?
                        <div>
                            <hr />
                            <div style={{ float: 'right' }}>
                                <OverlayTrigger
                                    key={"tooltip-lr"}
                                    placement={"top"}
                                    overlay={
                                        <Tooltip id={`tooltip-lr`}>
                                            Live Rate
                                                                    </Tooltip>
                                    }
                                >
                                    <i onClick={() => onShowLiveRate()} title="Live Rate" className='fas fa-chart-line' style={{ cursor: 'pointer', fontSize: 25, marginRight: 12 }} ></i>
                                </OverlayTrigger>


                                <OverlayTrigger
                                    key={"tooltip-save"}
                                    placement={"top"}
                                    overlay={
                                        <Tooltip id={`tooltip-save`}>
                                            Save
                                                                    </Tooltip>
                                    }
                                >
                                    <i onClick={() => onSaveClick()} title="Save" className='fas fa-save' style={{ cursor: 'pointer', fontSize: 25, marginRight: 12 }} ></i>

                                </OverlayTrigger>

                                <OverlayTrigger
                                    key={"tooltip-r"}
                                    placement={"top"}
                                    overlay={
                                        <Tooltip id={`tooltip-r`}>
                                            Reset Current Cell value
                                                                    </Tooltip>
                                    }
                                >
                                    <i onClick={() => onResetClick()} title="Reset Current Cell value" className='fas fa-undo' style={{ cursor: 'pointer', fontSize: 22, marginRight: 7 }}></i>
                                </OverlayTrigger>

                                {/* <i onClick={() => onResetAllClick()} className='fa fa-history' style={{ cursor: 'pointer', fontSize: 22, marginRight: 7 }}></i> */}

                            </div> </div> : null}
                    <div>
                        <div style={{ marginBottom: 4 }}><p><em>Note: Please note that rates are subject to change any time and are only locked in when the transaction has been finalised.</em></p></div>
                    </div>
                    <div>
                        {reportData.length > 0 ?

                            <div>{tableGenerator()}</div>
                            : <Spinner animation="border" size="lg" style={{ marginLeft: 500, marginTop: 150 }} />
                        }
                    </div>

                </div >


            </div >

        );
    }

    const onCloseLivaeRate = () => {
        SetshowLiveRate(false);
    }

    const handleFromCurrencyChange = (e) => {
        SetfromCurrency(e.target.value);
        populateLiveRates(liveRates, e.target.value, toCurrency);
    }

    const handleToCurrencyChange = (e) => {
        SettoCurrency(e.target.value);
        populateLiveRates(liveRates, fromCurrency, e.target.value);
    }
    //
    return <React.Fragment>
        {isAdmin &&
            <div>
                <h1 id="tabelLabel">
                    <div>
                        <div className="row">
                            <div class="col align-self-start">
                                <h6 className="float-left"><strong>Last Updated: {moment.utc(snapShotDate).tz("Australia/Sydney").format('DD MMM YYYY, h:mm a')}</strong></h6>

                            </div>
                            <div class="col align-self-end">
                                <button type="button" className="btn btn-primary float-right"
                                    onClick={getlatestRates}>Get latest live rate</button>
                            </div>
                        </div>

                        <div className="row" style={{ marginTop: 6 }}>
                            <div class="col align-self-end">
                                <button type="button" className="btn btn-sm btn-warning float-right" onClick={onResetAllClick}>
                                    Reset All
                            </button>
                            </div>
                        </div>

                    </div>

                </h1>

            </div>
        }

        {!isAdmin &&
            <div>
                <h1 id="tabelLabel">
                    <div>
                        <div className="row">
                            <div class="col align-self-start">
                                <h6 className="float-left"><strong>Last Updated: {moment.utc(snapShotDate).tz("Australia/Sydney").format('DD MMM YYYY, h:mm a')}</strong></h6>

                            </div>

                        </div>



                    </div>

                </h1>

            </div>
        }



        {createTable()}

        <Modal show={showLiveRate} onHide={onCloseLivaeRate} class="modal-lg modal-dialog">
            <Modal.Header closeButton >
                <div>
                    <Modal.Title>Live Rate</Modal.Title>
                    <Container>
                        <Row md={12}>
                            <Col md={6}><Form.Label style={{ marginTop: 10 }}>From Currency</Form.Label></Col>
                            <Col md={6}><Form.Label style={{ marginTop: 10 }}>To Currency</Form.Label></Col>
                        </Row >
                        <Row md={12}>
                            <Col md={6}>
                                <Form.Control as="select" name="TocurrencyId" onChange={handleFromCurrencyChange}>
                                    <option value="0">Select From Currency</option>
                                    {currencies.map(currency => (
                                        <option
                                            key={currency.key}
                                            value={currency.Name}
                                        >
                                            {currency.value}
                                        </option>
                                    ))}
                                </Form.Control>

                            </Col>
                            <Col md={6}>
                                <Form.Control as="select" name="FromcurrencyId" onChange={handleToCurrencyChange}>
                                    <option value="0">Select To Currency</option>
                                    {currencies.map(currency => (
                                        <option
                                            key={currency.key}
                                            value={currency.Name}
                                        >
                                            {currency.value}
                                        </option>
                                    ))}
                                </Form.Control>

                            </Col>

                        </Row>
                    </Container>
                    <hr />
                </div>
            </Modal.Header>
            <Modal.Body style={{ 'max-height': 'calc(100vh - 300px)', 'overflow-y': 'auto' }}>

                <Container>
                    <Row md={12}>
                        <Col md={3}>
                            <Form.Label >From</Form.Label>
                        </Col>
                        <Col md={3}>
                            <Form.Label >TO</Form.Label>
                        </Col>
                        <Col md={3}>
                            <div style={{ textAlign: 'right' }}>Value</div>
                        </Col>
                    </Row >
                    {filterLiveRates.map((r, i) => (

                        < Row md={12} >
                            <Col md={3} style={{ marginTop: 8 }}>
                                <div >
                                    <img width="24" src={require('../asset/images/flags/' + r.fromCurrencyCode.toLowerCase() + '.png')}></img>
                                    {" "}
                                    {r.fromCurrencyCode}
                                </div>
                            </Col>
                            <Col md={3} style={{ marginTop: 8 }}>
                                <div>
                                    <img width="24" src={require('../asset/images/flags/' + r.toCurrencyCode.toLowerCase() + '.png')}></img>
                                    {" "}
                                    {r.toCurrencyCode}
                                </div>
                            </Col>
                            <Col md={3} style={{ marginTop: 8 }}>
                                <div style={{ textAlign: 'right' }}> {Number(r.rate).toFixed(4)}</div>
                            </Col>
                        </Row>
                    ))}

                </Container>
            </Modal.Body >
            <Modal.Footer>
                <Button variant="secondary" onClick={onCloseLivaeRate} >
                    Close
                </Button>
            </Modal.Footer>
        </Modal >
    </React.Fragment >;

}

const TooltipItem = props => {
    const { item, id } = props;
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <span>
            <Button className="mr-1" color="secondary" id={"Tooltip-" + id}>
                {item.toolTip}
            </Button>
            <Tooltip
                placement="bottom"
                isOpen={tooltipOpen}
                target={"Tooltip-" + id}
                toggle={toggle}
            >
                Tooltip Content!
        </Tooltip>
        </span>
    );
};