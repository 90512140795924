import http from "./httpService";
const apiUrl = require("../config").get(process.env.NODE_ENV).apiUrl;

export function getLatestRate() {
    const endPoint = apiUrl + "/CurrencyRate/getLatestRate";
    return http.get(endPoint);
}

export function getLiveRates() {
    const endPoint = apiUrl + "/CurrencyRate/getLiveRates";
    return http.get(endPoint);
}

export function searchReportData(productId, currencyId) {
    const endPoint = apiUrl + "/CurrencyRate/rateReport?productId=" + productId + "&currencyId=" + currencyId;
    // const endPoint = apiUrl + "/CurrencyRate/rateReport/"+productId+"/"+currencyId;
    return http.get(endPoint);
}

export function getLatestDate() {
    const endPoint = apiUrl + "/CurrencyRate/latestReportDate";
    return http.get(endPoint);
}

export function getServiceProvider() {
    const endPoint = apiUrl + "/CurrencyRate/getServiceProvider";
    return http.get(endPoint);
}
