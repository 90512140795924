import http from "./httpService";
const apiUrl = require("../config").get(process.env.NODE_ENV).apiUrl;

export function getIsValidUser() {
    const endPoint = apiUrl + "/Aut/getIsValidUser";
    return http.get(endPoint);
}

export function logoutUser() {
    const endPoint = apiUrl + "/Aut/logoutUser";
    return http.get(endPoint);
}



