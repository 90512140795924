import http from "./httpService";
const apiUrl = require("../config").get(process.env.NODE_ENV).apiUrl;

export function getProductsCurrency() {
  const endPoint = apiUrl + "/ProductCurrency/getProductCurrencies";
  return http.get(endPoint);
}

export function saveProductCurrency(update) {
  const endPoint = apiUrl + "/ProductCurrency/saveProductCurrecy";
  return http.post(endPoint, update);
}

export function updateProductCurrency(id, update) {
  const endPoint = apiUrl + "/ProductCurrency/updateProductCurrency/" + id;
  return http.put(endPoint, update);
}

export function updateProductCurrencyList(updateList) {
  const endPoint = apiUrl + "/ProductCurrency/updateProductCurrencyList";
  return http.post(endPoint, updateList);
}

export function resetProductCurrencyValue(updateItem) {
  const endPoint = apiUrl + "/ProductCurrency/ResetProductCurrencyValue";
  return http.post(endPoint, updateItem);
}

export function resetAllProductCurrencyValue() {
  const endPoint = apiUrl + "/ProductCurrency/ResetAllProductCurrencyValue";
  return http.post(endPoint);
}





