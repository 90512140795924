import http from "./httpService";
const apiUrl = require("../config").get(process.env.NODE_ENV).apiUrl;

export function getGroups() {
  const endPoint = apiUrl + "/Group/getGroups";
  return http.get(endPoint);
}

export function saveGroup(group) {
  const endPoint = apiUrl + "/Group/saveGroup";
  return http.post(endPoint,group);
}

export function updateGroup(id, group) {
  const endPoint = apiUrl + "/Group/updateGroup/" + id;
  return http.put(endPoint,group);
}

export function getGroupsList(id) {
  const endPoint = apiUrl + "/Group/getGroupList" + id;
  return http.get(endPoint);
}

export function getGroupById(id) {
  const endPoint = apiUrl + "/Group/getGroupById" + id;
  return http.get(endPoint);
}

export function deleteGroup(id) {
  const endPoint = apiUrl + "/Group/deleteGroup/" + id;
  return http.put(endPoint);
}