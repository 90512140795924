import React, { useState, useEffect } from "react";
import { getGroups, saveGroup, updateGroup, deleteGroup } from "../service/groupService";
import { Button, Modal, InputGroup, FormControl, Form, Spinner } from 'react-bootstrap';

export default function Group() {

  const initialGroupState = {
    id: null,
    name: null,
    description: null,
    sortingOrder: null
  };

  const [addGroup, setAddGroup] = useState(initialGroupState);
  const [editGroup, setEditGroup] = useState(initialGroupState);
  const [submitted, setSubmitted] = useState(false);

  const [groups, setGroups] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const handleClose = () => setShowAdd(false, setAddGroup(initialGroupState));
  const handleShow = () => setShowAdd(true);

  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = (group) => {
    console.log("====== group ======", group);
    setEditGroup(group);
    setShowEdit(true)
  };

  const [errors, setErrors] = useState({});

  useEffect(() => {
    getGroupData();
  }, []);


  const validate = () => {
    let isValid = true;
    let temp = {}
    if (addGroup.name == null || addGroup.name == "") {
      isValid = false;
      temp.name = "This field is requierd";
    }

    if (addGroup.description == null || addGroup.description == "") {
      isValid = false;
      temp.description = "This field is requierd";
    }
    if (addGroup.sortingOrder == null || addGroup.sortingOrder == "") {
      isValid = false;
      temp.sortingOrder = "This field is requierd";
    }
    setErrors(temp)


    return isValid;
  }

  // add input change
  const handleInputChange = event => {
    const { name, value } = event.target;
    setAddGroup({ ...addGroup, [name]: value });
  };


  // input edit change
  const handleInputEditChange = event => {
    const { name, value } = event.target;
    setEditGroup({ ...editGroup, [name]: value });
  };

  // Save Group
  const saveAddGroup = () => {
    if (validate()) {
      var data = {
        name: addGroup.name,
        description: addGroup.description,
        sortingOrder: addGroup.sortingOrder !== null ? parseInt(addGroup.sortingOrder) : null
      };

      saveGroup(data)
        .then((response) => {

          console.log(response);
          getGroupData();
          setShowAdd(false);
          setAddGroup(initialGroupState);
          alert("Product group Added Succefully")

        })
    }
    else {
      alert("Product group Added Unsuccessfull")
    }
  };

  // Edit Group
  const EditGroup = () => {
    var data = {
      name: editGroup.name,
      description: editGroup.description,
      sortingOrder: editGroup.sortingOrder !== null ? parseInt(editGroup.sortingOrder) : null
    };

    updateGroup(editGroup.id, data)
      .then(response => {
        // setEditGroup({ ...editGroup});
        console.log(response);
        getGroupData();
        setShowEdit(false);
        alert("Product group Edit Succefully")
      })
      .catch(e => {
        console.log(e);
        alert("Product group Edit Unsuccessfull")
      });
  };

  const deleteGroups = (id) => {
    if (id > 0) {
      if (window.confirm(
        "Delete Confirmation 1 of 2,\nDo you want to delete this record?"
      )) {
        if (window.confirm(
          "Delete Confirmation 2 of 2,\nDo you want to delete this record?"
        )) {
          deleteGroup(id)
            .then(response => {
              console.log(response.data);
              getGroupData();
            })
            .catch(e => {
              console.log(e);
            });
        }
      }
    }
  };


  const newGroup = () => {
    setAddGroup(initialGroupState);
    setSubmitted(false);
  };

  const getGroupData = () => {
    getGroups().then(res => {
      setGroups(res.data);
      console.log(res.data);
    }).catch();
  }


  const createTable = () => {
    return (
      <div>
        <table className="table" aria-labelledby="tabelLabel">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Display Sequence</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {groups != null && groups.length > 0 ? groups.map((group) =>
              <tr key={group.id}>
                <td>{group.name}</td>
                <td>{group.description}</td>
                <td style={{textAlign: 'center'}}>{group.sortingOrder}</td>
                <td>
                  <i onClick={() => handleShowEdit(group)} className='fas fa-edit' style={{ color: 'blue', cursor: 'pointer', fontSize: 18 }}></i>
                  <i onClick={() => deleteGroups(group.id)} className='fas fa-trash' style={{ color: 'red', cursor: 'pointer', marginLeft: 7, fontSize: 18 }}></i>
                </td>
              </tr>
            ) : (
                <Spinner animation="border" size="lg" style={{ marginLeft: 500, marginTop: 150 }} />
              )}
          </tbody>
        </table>
      </div>
    );
  }

  return <React.Fragment>
    <h1 id="tabelLabel">Product Group<button type="button" className="btn btn-primary float-right" onClick={handleShow}>Add Group</button></h1>
    {createTable()}

    <Modal show={showAdd} onHide={handleClose} >
      <Modal.Header closeButton>
        <Modal.Title>Add Product Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label style={{ marginTop: 10 }}>Group Name</Form.Label>
        <span style={{ color: "red", margin: 25 }}>{errors.name}</span>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Name"
            aria-label="name"
            onChange={handleInputChange}
            value={addGroup.name}
            name="name"
            aria-describedby="basic-addon1"
          />
        </InputGroup>

        <Form.Label style={{ marginTop: 10 }}>Description</Form.Label>
        <span style={{ color: "red", margin: 25 }}>{errors.description}</span>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Description"
            aria-label="Description"
            onChange={handleInputChange}
            value={addGroup.description}
            name="description"
            aria-describedby="basic-addon1"
          />
        </InputGroup>
        <Form.Label style={{ marginTop: 10 }}>Display Sequence</Form.Label>
        <span style={{ color: "red", margin: 25 }}>{errors.sortingOrder}</span>
        <InputGroup className="mb-3">
          <FormControl
            type="number"
            placeholder="Display Sequence"
            aria-label="Display Sequence"
            onChange={handleInputChange}
            value={addGroup.sortingOrder}
            name="sortingOrder"
            aria-describedby="basic-addon1"
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
          </Button>
        <Button variant="primary" onClick={saveAddGroup}>
          Add
          </Button>
      </Modal.Footer>
    </Modal>



    <Modal show={showEdit} onHide={handleCloseEdit}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Product Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label style={{ marginTop: 10 }}>Group Name</Form.Label>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Name"
            aria-label="name"
            onChange={handleInputEditChange}
            value={editGroup.name}
            name="name"
            aria-describedby="basic-addon1"
          />
        </InputGroup>
        <Form.Label style={{ marginTop: 10 }}>Description</Form.Label>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Description"
            aria-label="Description"
            onChange={handleInputEditChange}
            value={editGroup.description}
            name="description"
            aria-describedby="basic-addon1"
          />
        </InputGroup>
        <Form.Label style={{ marginTop: 10 }}>Display Sequence</Form.Label>
        <InputGroup className="mb-3">
          <FormControl
            type="number"
            placeholder="Display Sequence"
            aria-label="Display Sequence"
            onChange={handleInputEditChange}
            value={editGroup.sortingOrder}
            name="sortingOrder"
            aria-describedby="basic-addon1"
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseEdit}>
          Close
          </Button>
        <Button variant="primary" onClick={EditGroup}>
          Edit
          </Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>;

}

