import http from "./httpService";
const apiUrl = require("../config").get(process.env.NODE_ENV).apiUrl;

export function getProducts() {
  const endPoint = apiUrl + "/Product/getProducts";
  return http.get(endPoint);
}

export function saveProduct(product) {
  const endPoint = apiUrl + "/Product/saveProduct";
  console.log("======= save roducts ===", product);
  return http.post(endPoint, product);
}

export function updateProduct(id, product) {
  const endPoint = apiUrl + "/Product/updateProduct/" + id;
  return http.put(endPoint, product);
}

export function getGroups(id) {
  const endPoint = apiUrl + "/Product/getGroups/" + id;
  return http.get(endPoint);
}

export function getCountryList() {
  const endPoint = apiUrl + "/Product/getCountryList"
  return http.get(endPoint);
}



export function getCurrecies() {
  const endPoint = apiUrl + "/Product/getCurrencies";
  return http.get(endPoint);
}

export function getProductsReportList() {
  const endPoint = apiUrl + "/Product/getProductsReportList";
  return http.get(endPoint);
}

export function getCurrencyPairFeeForProduct(id, countryCode) {
  const endPoint = apiUrl + "/Product/getCurrencyPairFeeForProduct?id=" + id + '&coutryCode=' + countryCode;
  return http.get(endPoint);
}

export function deleteCurrencyPair(id) {
  const endPoint = apiUrl + "/Product/deleteCurrencyPairFee/" + id;
  return http.put(endPoint);
}

export function saveCurrencyPairFee(currencyPairFees) {
  const endPoint = apiUrl + "/Product/saveCurrencyPairFee";
  return http.post(endPoint, currencyPairFees);
}

export function deleteProduct(id) {
  const endPoint = apiUrl + "/Product/deleteProduct/" + id;
  return http.put(endPoint);
}

export function searchProductData(groupId, productId, countrygroupId) {
  const endPoint = apiUrl + "/Product/getProductsFilter?groupId=" + groupId + "&productId=" + productId + "&isCountryGroup=" + countrygroupId;
  // const endPoint = apiUrl + "/CurrencyRate/rateReport/"+productId+"/"+currencyId;
  return http.get(endPoint);
}

export function getProductsForCurencyCharges(countryCode, productId) {
  console.log("countryCode");
  console.log(countryCode);
  const endPoint = apiUrl + "/Product/GetProductsForCurencyCharges?countryCode=" + countryCode + "&productId=" + productId;
  // const endPoint = apiUrl + "/CurrencyRate/rateReport/"+productId+"/"+currencyId;
  return http.get(endPoint);
}

