import React, { useState, useEffect } from "react";
import {
    getProducts, saveProduct, updateProduct, getGroups, getCurrecies, deleteProduct,
    getProductsReportList, searchProductData, saveCurrencyPairFee,
    getCurrencyPairFeeForProduct, deleteCurrencyPair, getCountryList, getProductsForCurencyCharges
} from "../service/productService";
import { Button, Modal, InputGroup, FormControl, Dropdown, Form, Spinner } from 'react-bootstrap';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import '../components/Product.css'
import { Col, Container, Row } from "reactstrap";
import { getgroups, setgroups } from "process";

import { getIsValidUser } from "../service/autService";


export default function ProductCharges() {

    const initialProductState = {
        id: null,
        name: null,
        description: null,
        groupId: null,
        productId: null,
        productCurrencies: [{
            id: 0,
            index: 0,
            fromCurrencyId: 1,
            toCurrencyId: null,
            multiplier: 1.0000,
        }]
    };

    const initialEditProductState = {
        id: null,
        name: null,
        description: null,
        groupId: null,
        productId: null,
        productCurrencies: [{
            id: 0,
            index: 0,
            fromCurrencyId: 1,
            toCurrencyId: null,
            multiplier: null,
        }]
    };

    const filedDirty = {
        indexG: -1,
        isDirty: false,
        indxP: -1
    }

    const filterProductState = {
        productId: 0,
        groupId: 0
    }

    const [addProduct, setAddProduct] = useState(initialProductState);
    const [editProduct, setEditProduct] = useState(initialEditProductState);
    const [fiterProduct, setFiterProduct] = useState(filterProductState);
    const [fiterGroup, setFiterGroup] = useState(filterProductState);
    const [listBox, setListBox] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [saved, setSaved] = useState(filedDirty);
    const [groupType, setgroupType] = useState(2);
    const [groupTypeSearch, setgroupTypeSearch] = useState(0);
    const [selectedCountry, setselectedCountry] = useState(null);
    const [selectedCountryName, setselectedCountryName] = useState(null);


    const [selectedproductCurrency, setSelectedproductCurrency] = useState({});
    const [curentProductName, setCurentProductName] = useState('');
    const [curentProductGroupName, setCurentProductGroupName] = useState('');
    const [fromCountry, setfromCountry] = useState('');
    const [toCountry, settoCountry] = useState('');










    const [products, setProducts] = useState([]);
    const [productsForEdit, setProductsForEdit] = useState([]);

    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showCurencyPair, setshowCurencyPair] = useState(false);
    const [curencyPairFees, setcurencyPairFees] = useState([]);



    const [productsList, setProductList] = useState([]);

    const handleClose = () => setShowAdd(false, setAddProduct(initialProductState));
    const handleShow = () => setShowAdd(true);

    const loginUrl = require("../config").get(process.env.NODE_ENV).loginUrl;


    useEffect(() => {
        getIsValidUser().then(res => {
            if (res.data != true) {
        window.location.replace(loginUrl)
                
            }
            // a valid authenticated user
        }).catch(err => {
        window.location.replace(loginUrl)

        });
    })



    const handleCloseCurencyPair = () => {
        setshowCurencyPair(false);
        setSelectedproductCurrency(null);
        searchReprot();

    };

    const SaveCloseCurencyPair = () => {
        let isValid = true;
        let message = "Error: fee structure seems not consistent, please check and correct from, to and fee values and try again."
        for (var i = 0; i < curencyPairFees.length; i++) {
            let a = curencyPairFees[i];
            if (a.toValue == undefined || a.toValue <= 0 || a.fromValue == undefined) {
                isValid = false;
                alert(message);
                break;
            }
            if (a.fromValue >= a.toValue) {
                isValid = false;
                alert(message);
                break;;
            }
            else if ((i + 1) <= curencyPairFees.length - 1) {
                if (a.toValue > curencyPairFees[i + 1].fromValue) {
                    isValid = false;
                    alert(message);
                    break;;
                }
            }

        }

        if (isValid == true) {
            saveCurrencyPairFee(curencyPairFees)
                .then(response => {
                    // setEditGroup({ ...editGroup});
                    console.log(response);
                    alert("Successfully Saved!");
                    setshowCurencyPair(false);
                    searchReprot();
                })
                .catch(e => {
                    console.log(e);
                    alert("Error on saving, please try again. ");
                    setSaved(false);
                });
        }

    }

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = (product) => {
        console.log("=== product =====", product);
        let index = 0;
        product.productCurrencies.forEach(element => {
            element.index = index++;
        });
        setEditProduct(product);
        setShowEdit(true)
    };

    const [groups, setGroups] = useState([]);
    const [groupsforEdit, setGroupsforEdit] = useState([]);
    const [countryList, setcountryList] = useState([]);
    const [currencies, setCurrencies] = useState([]);

    const [errors, setErrors] = useState({});

    const [showSpinner, setshowSpinner] = useState(false);

    // useEffect(() => {
    //     getGroupsDataForEdit(2);
    //     getProductDataForEdit();
    //     getCureencyData();
    //     getProductList();
    // }, [editProduct]);

    // useEffect(() => {
    //     searchReprot();
    // }, [fiterGroup, fiterProduct]);

    useEffect(() => {
        getProductList();
        searchReprot();
        getCountry();
    }, []);

    useEffect(() => {
        searchReprot();
    }, [fiterProduct, selectedCountry]);


    // useEffect(() => {
    //     //alert("1")
    // }, [curencyPairFees]);

    // useEffect(() => {
    //   //getGroupsData(groupType);
    //   getGroupsDataForEdit(groupType);
    // }, [groupType]);

    // useEffect(() => {
    //     getGroupsData(groupTypeSearch);
    //     //getGroupsDataForEdit(groupType);
    // }, [groupTypeSearch]);



    const handleCurrencyPairAddClick = () => {
        let lastToVlaue = curencyPairFees[curencyPairFees.length - 1].toValue;
        let productId = curencyPairFees[0].productCurencyId;
        let fee = curencyPairFees[curencyPairFees.length - 1].fee;
        if (lastToVlaue !== undefined && lastToVlaue > 0 && fee !== undefined && fee >= 0) {
            //let data = curencyPairFees;
            //data.push({ fromValue: lastToVlaue, toValue: undefined, fee: undefined, productId: productId });
            setcurencyPairFees([...curencyPairFees, { countryCode: selectedCountry, fromValue: lastToVlaue + 1, toValue: undefined, fee: undefined, productCurencyId: productId, id: 0 }]);
            //  setcurencyPairFees(data);
            console.log(curencyPairFees);
            //console.log(data);
        }

    }

    const handleCurrencyPairDeleteClick = () => {
        let lastItem = curencyPairFees[curencyPairFees.length - 1];

        if (lastItem.id === 0) {
            deleteCurrencyPairValue(lastItem);
        }
        else {
            if (window.confirm('Are you sure to delete this record?')) {
                deleteCurrencyPair(lastItem.id).then(res => {
                    alert("Successfully Deleted.");
                    manageRatePairFee(lastItem.productCurencyId, null, null);
                }).catch(err => {
                    console.log(err);
                });
            }
        }
    }

    const deleteCurrencyPairValue = (lastItem) => {
        let newArr = [...curencyPairFees];
        let arraylength = curencyPairFees.length;
        let index = newArr.indexOf(lastItem);
        newArr.splice(index, 1);
        setcurencyPairFees(newArr);

        // manageRatePairFee(lastItem.productCurencyId);


    }

    const handleInputChange = (event, index, type) => {
        const { name, value } = event.target;
        if (index === -1) {
            setAddProduct({ ...addProduct, [name]: value });
        }
        // if (name === "groupTypeId") {
        //   setgroupType(value);
        //   //getGroupsData(value);
        //   console.log(value);
        // }
        else {
            let preProducts = { ...addProduct };
            let selCurrency = preProducts.productCurrencies.filter(x => x.index === index);
            if (name === "toCurrencyId") {
                let previousCurrency = preProducts.productCurrencies.filter(x => x.toCurrencyId == value);
                if (previousCurrency.length > 0) {
                    alert("This Currency is already selected");
                    selCurrency[0][name] = null;
                    setAddProduct(preProducts);
                    return;
                }
            }

            if (selCurrency !== undefined && selCurrency.length > 0) {
                selCurrency[0][name] = value;
                if (type === 1) {
                    selCurrency[0][name] = parseInt(value);
                }
                else if (type === 2) {
                    selCurrency[0][name] = parseFloat(value);
                }
                setAddProduct(preProducts);
            }
        }
    };

    const handleInputChangeProductFilter = (event) => {
        const { name, value } = event.target;
        setFiterProduct({ ...fiterProduct, [name]: value });
        //searchReprot();
    };

    const handleInputChangeGroupFilter = (event) => {
        const { name, value } = event.target;
        console.log("yyyyyyyyyyyyyyyyyyyyyyyyy");

        console.log(name);
        console.log(value);

        if (name === "groupTypeId") {
            setgroupTypeSearch(value);
        }
        setFiterGroup({ ...fiterGroup, [name]: value });
        //searchReprot();
    };

    const handleInputChangeCounty = (event) => {

        console.log("event c c", event.target.value);
        setProducts([]);

        const { name, value } = event.target;
        setselectedCountryName(countryList.find(a => a.code == value).name);
        console.log("Searchhhhhhhhhhh", value);
        console.log(value);
        setselectedCountry(value);
        console.log("selectedCountry", selectedCountry);
        //searchReprotByCountryCode(value);
    };

    const handleOnChange = (listBox) => {
        setListBox(listBox)
    };


    const searchReprot = () => {
        setProducts([]);
        console.log(fiterProduct)
        if (!(fiterProduct && selectedCountry)) {
            return;
        }
        getProductsForCurencyCharges(selectedCountry == null ? '' : selectedCountry, fiterProduct === undefined ? 0 : fiterProduct.productId).then(res => {
            console.log("xxxxxxxxx");
            console.log(res.data);
            setProducts(res.data);
        }).catch(err => {
            console.log(err);
        });
    }


    const searchReprotByCountryCode = (inputselectedCountry) => {
        getProductsForCurencyCharges(inputselectedCountry).then(res => {
            console.log("xxxxxxxxx");
            console.log(res.data);
            setProducts(res.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const validate = () => {
        let isValid = true;
        let temp = {}
        if (addProduct.name == null || addProduct.name == "") {
            isValid = false;
            temp.name = "This field is requierd";
        }
        if (addProduct.description == null || addProduct.description == "") {
            isValid = false;
            temp.description = "This field is requierd";
        }
        if (addProduct.groupId == null || addProduct.groupId == "") {
            isValid = false;
            temp.groupId = "This field is requierd";
        }
        if (addProduct.productCurrencies == null || addProduct.productCurrencies == "") {
            isValid = false;
            temp.multiplier = "This field is requierd";
        }
        setErrors(temp)
        return isValid;
    }

    const EditMultiplier = (productId, indexP, indexG) => {
        // name: editProduct.name,
        // description: editProduct.description,
        // groupId: editProduct.groupId !== null ? parseInt(editProduct.groupId) : null,
        // ProductCurrencies: editProduct.productCurrencies
        var group = products[indexG];
        var data = {
            id: productId,
            name: group.products[indexP].name,
            description: group.products[indexP].description,
            groupId: group.groupId,
            ProductCurrencies: group.products[indexP].productCurrencies
        };

        updateProduct(data.id, data)
            .then(response => {
                // setEditGroup({ ...editGroup});
                console.log(response);
                getProductData();
                setShowEdit(false);
                alert("Product Edit Successfully");
                setSaved(true);
            })
            .catch(e => {
                console.log(e);
                alert("Error on product edit, please try again");
                setSaved(false);
            });
    };

    const handleInputEditChange2 = (event, indexPC, indexP, indexG) => {
        const { name, value } = event.target;
        let newGroup = [...products];
        newGroup[indexG].products[indexP].productCurrencies[indexPC].multiplier = value;
        setProducts(newGroup);
        handleDirty(indexG, true, indexP);
    };

    const handleDirty = (indexG, isDirty, indexP) => {
        setSaved({ indexG, isDirty, indexP });
    }

    const handleCuurencyPairEditChange = (event, index, arrayIndex) => {
        let newArr = [...curencyPairFees];
        //let pair = newArr[arrayIndex];
        const { name, value } = event.target;
        if (index === 1) {
            // if (newArr[arrayIndex].fromValue >= value) {
            //   newArr[arrayIndex].toValue = parseInt(newArr[arrayIndex].fromValue) + 1;
            // }
            // else if ((curencyPairFees.length > parseInt(arrayIndex) + 1) && curencyPairFees[parseInt(arrayIndex) + 1].fromValue < value) {
            //   newArr[arrayIndex].toValue = curencyPairFees[parseInt(arrayIndex) + 1].fromValue;

            // }
            // else {
            //   newArr[arrayIndex].toValue = parseInt(value);
            // }
            // pair.toValue = parseInt(value);
            newArr[arrayIndex].toValue = parseInt(value);
        }
        else if (index === 2) {
            newArr[arrayIndex].fee = parseInt(value);
            // pair.fee = parseInt(value);
        }

        setcurencyPairFees(newArr);

        console.log(curencyPairFees);
    }

    // input edit change
    const handleInputEditChange = (event, index, type) => {
        debugger;
        const { name, value } = event.target;
        if (index === -1) {
            setEditProduct({ ...editProduct, [name]: value });
        }
        else {
            let preProducts = { ...editProduct };
            let selCurrency = preProducts.productCurrencies.filter(x => x.index === index);
            if (selCurrency !== undefined && selCurrency.length > 0) {
                selCurrency[0][name] = value;
                if (type === 1) {
                    selCurrency[0][name] = parseInt(value);
                }
                else if (type === 2) {
                    selCurrency[0][name] = parseFloat(value);
                }
                setEditProduct(preProducts);
            }

        }
    };

    // add product plus and remove
    const handleAddClick = () => {
        let preProducts = { ...addProduct };
        let currencyLength = preProducts.productCurrencies.length;
        preProducts.productCurrencies.push({
            index: currencyLength,
            fromCurrencyId: 1,
            toCurrencyId: null,
            multiplier: null,
        });
        setAddProduct(preProducts);
    };

    const handleRemoveClick = (index) => {
        if (index > 0) {
            if (window.confirm(
                "Delete Confirmation ,\nDo you want to remove this record?"
            )) {
                const preProducts = { ...addProduct };
                preProducts.productCurrencies.splice(index, 1);
                //list.splice(index, 1);
                setAddProduct(preProducts);
            }
        }
    };



    // edit product plus and remove
    const handleEditAddClick = () => {
        let preProducts = { ...editProduct };
        let currencyLength = preProducts.productCurrencies.length;
        preProducts.productCurrencies.push({
            index: currencyLength,
            fromCurrencyId: 1,
            toCurrencyId: null,
            multiplier: null,
        })
        setEditProduct(preProducts);
    };

    const handleEditRemoveClick = index => {
        if (index > 0) {
            if (window.confirm(
                "Delete Confirmation ,\nDo you want to remove this record?"
            )) {
                const preProducts = { ...editProduct };
                preProducts.productCurrencies.splice(index, 1);
                //list.splice(index, 1);
                setEditProduct(preProducts);
            }
        }
    };

    // preProducts.productCurrencies.push({
    //   index: currencyLength,
    //   fromCurrencyId: 1,
    //   toCurrencyId: null,
    //   multiplier: null,
    // });

    const setProductToCurruncies = () => {
        const dataList = [];
        for (let index = 0; index < listBox.length; index++) {
            const data = {
                id: 0,
                toCurrencyId: listBox[index],
                fromCurrencyId: 1,
                multiplier: 1
            }
            dataList.push(data);
        }
        return dataList;
    };

    const saveProductData = () => {
        if (validate()) {
            console.log(addProduct.productCurrencies);
            var data = {
                name: addProduct.name,
                description: addProduct.description,
                groupId: addProduct.groupId !== null ? parseInt(addProduct.groupId) : null,
                ProductCurrencies: setProductToCurruncies()
            };
            saveProduct(data)
                .then(response => {
                    console.log(response);
                    getProductList();
                    getProductData();
                    setShowAdd(false);
                    setAddProduct(initialProductState);
                    // getGroupsData(0);
                    // addProduct.productCurrencies = []; todo need to initialize selected list of currency
                    alert("Product is added successfully.")

                })
        }
        else {
            //alert("Product is added successfully.")
        }
    };

    // Edit Product
    const Editproduct = () => {
        var data = {
            name: editProduct.name,
            description: editProduct.description,
            groupId: editProduct.groupId !== null ? parseInt(editProduct.groupId) : null,
            ProductCurrencies: editProduct.productCurrencies
        };

        updateProduct(editProduct.id, data)
            .then(response => {
                // setEditGroup({ ...editGroup});
                console.log(response);
                getProductData();
                setShowEdit(false);
                alert("Product Edit Successfully")
            })
            .catch(e => {
                console.log(e);
                alert("Product Edit Unsuccessfull")
            });
    };

    // const newGroup = () => {
    //   setAddProduct(initialProductState);
    //   setSubmitted(false);
    // };

    const manageRatePairFee = (productCurrencyId, productCurrency, product) => {
        if (selectedCountry == null || selectedCountry == '') {
            alert('Please select country for rate setup.');
            return;
        }
        getCurrencyPairFeeForProduct(productCurrencyId, selectedCountry).then(res => {
            console.log("nnnnnnnnnnnnnnnnn");
            console.log(res.data);
            if (res.data.length > 0) {
                setcurencyPairFees(res.data);
            }
            else {
                setcurencyPairFees([{ countryCode: selectedCountry, id: 0, fromValue: 0, toValue: undefined, fee: undefined, productCurencyId: productCurrencyId }])
            }
            setshowCurencyPair(true);
            if (productCurrency != null) {
                //     let fromCountryName = countryList.find(a => a.code == productCurrency.fromCurrency.countryCode);
                //     let toCountryName = countryList.find(a => a.code == productCurrency.toCurrency.countryCode);

                //    setfromCountry(fromCountryName);
                //    settoCountry(toCountryName);


                setSelectedproductCurrency(productCurrency);

            }

            if (product != null && product != undefined) {
                console.log(product);
                setCurentProductName(product.name);
                setCurentProductGroupName(product.group.name);

            }
        }).catch(err => {
            setProducts([])
            console.log(products);
        });
    }

    const deleteProducts = (id) => {
        if (id > 0) {
            if (window.confirm(
                "Delete Confirmation 1 of 2,\nDo you want to delete this record?"
            )) {
                if (window.confirm(
                    "Delete Confirmation 2 of 2,\nDo you want to delete this record?"
                )) {
                    deleteProduct(id)
                        .then(response => {
                            console.log(response.data);
                            getProductData();
                        })
                        .catch(e => {
                            console.log(e);
                        });
                }
            }
        }
    };



    const getProductData = () => {
        console.log("yyyyyyyyyyy");
        getProducts().then(res => {
            setProducts(res.data);
        }).catch(err => {
            setProducts([])
            console.log(products);
        });
    }

    const getProductDataForEdit = () => {
        getProducts().then(res => {
            setProductsForEdit(res.data);
        }).catch(err => {
            setProductsForEdit([])
            console.log(products);
        });
    }

    const getGroupsData = (id) => {
        getGroups(id).then(res => {
            setGroups(res.data);
            console.log("======== group data====", res.data);
        }).catch(err => {
            console.log(err);
        });


    }

    const getGroupsDataForEdit = (id) => {
        getGroups(id).then(res => {
            setGroupsforEdit(res.data);
            console.log("res.data");
            console.log(res.data);
        }).catch(err => {
            console.log(err);
        });


    }

    const getCureencyData = () => {
        getCurrecies().then(res => {
            setCurrencies(res.data);
            console.log("======== currency data====", res);
        }).catch(err => {
            console.log(err);
        });
    }

    const getProductList = () => {
        getProductsReportList().then(res => {
            setProductList(res.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const getCountry = () => {
        getCountryList().then(res => {
            setcountryList(res.data);
        }).catch(err => {
            console.log(err);
        });
    }

    const getCurrencyDualList = currencies.map((currency) => {
        return { value: currency.key, label: currency.value };
    });

    const options = [
        { label: getCurrencyDualList }
    ];


    const createTable = () => {
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', flex: 1 }}>
                    {/* <div style={{ width: '25%' }}>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Group Type</Form.Label>
              <span style={{ color: "red", margin: 25 }}>{errors.groupId}</span>
              <Form.Control as="select" onChange={(event) => handleInputChangeGroupFilter(event)} name="groupTypeId">
                <option value="0">Select a Group Type</option>
                <option value="1">Genric Group</option>
                <option value="2">Country Group</option>
              </Form.Control>

            </Form.Group>
          </div> */}
                    <div style={{ width: '25%' }}>
                        <Form.Group controlId="exampleForm.ControlSelect156">
                            <Form.Label>Select Country </Form.Label>
                            {/* <span style={{ color: "red", margin: 25 }}>{errors.groupId}</span> */}
                            <Form.Control as="select" key='co-1' onChange={handleInputChangeCounty} name="groupId">
                                <option value="0">Select a Country</option>
                                {countryList && countryList.map(c => (
                                    <option
                                        key={c.id}
                                        value={c.code}

                                    >
                                        {/* <div style={{ flex: 1, padding: '5px', marginTop: -17 }}>
                                            <div>
                                                {
                                                    <img width="24" alt="image not found."
                                                        src={require('../asset/images/flags/' + c.code.toLowerCase() + '.png')}></img>}
                                                {" "}
                                                {c.code} - {c.name}
                                            </div>
                                        </div> */}
                                        {c.name} ({c.code})
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </div>
                    <div style={{ width: '25%' }}>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Select Product</Form.Label>
                            <Form.Control as="select" onChange={handleInputChangeProductFilter} name="productId">
                                <option value="0">All Products</option>
                                {productsList.map(reportProduct => (
                                    <option

                                        key={reportProduct.key}
                                        value={reportProduct.key}
                                    >
                                        {reportProduct.value}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </div>
                    <div>
                        <Form >
                            <Button type="button" onClick={searchReprot} style={{ marginTop: 30 }}>Refresh</Button>
                        </Form>
                    </div>
                </div>
                <div>
                    {products != null && products.length > 0 ? products.map((group, k) =>
                        <div key={k}>
                            {/*<div style={{ display: 'flex', height: 40 }}>*/}
                            {/*    <div style={{ flex: 1, padding: '10px' }}>*/}
                            {/*        <p style={{ color: 'white', fontWeight: 700 }}>Group : {group.groupName}</p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div>
                                <div>
                                    {selectedCountry && group.products.map((product, j) =>
                                        <div key={j}>
                                            <div >
                                                <span style={{ display: 'flex', flexDirection: 'row', fontWeight: 700 }}>Product :  {product.name}

                                                    {/* <div style={{ marginLeft: 'auto' }} key={j}>
                                                        <i onClick={() => EditMultiplier(product.id, j, k)} className='fas fa-save' style={{ color: (saved.isDirty && saved.indexP === j && saved.indexG === k) ? 'red' : 'black', cursor: 'pointer', fontSize: 24, marginRight: 7 }} > </i>
                                                        <i onClick={() => handleShowEdit(product)} className='fas fa-edit' style={{ cursor: 'pointer', fontSize: 24 }}> </i>
                                                        <i onClick={() => deleteProducts(product.id)} className='fas fa-trash' style={{ cursor: 'pointer', marginLeft: 7, fontSize: 24 }}> </i>
                                                    </div> */}

                                                </span>
                                                <hr style={{ backgroundColor: '#afb1ae', height: 0.5 }} />
                                            </div>
                                            <div style={{ padding: '10px' }}>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ flex: 1, padding: '5px' }}>
                                                        <p style={{ fontWeight: 700 }}>From</p>
                                                    </div>
                                                    <div style={{ flex: 1, padding: '5px', marginLeft: '25px' }}>
                                                        <p style={{ fontWeight: 700 }}>To</p>
                                                    </div>
                                                    <div style={{ flex: 1, padding: '5px', marginRight: '10px' }}>
                                                        <p style={{ fontWeight: 700 }}>Fee</p>
                                                    </div>
                                                </div>
                                                <hr style={{ marginTop: -16 }} />
                                                <div style={{ padding: '5px' }}>
                                                    {product.productCurrencies.map((productCurrency, i) =>
                                                        <div key={i} style={productCurrency.hasSetupCurrencyCharges ? { padding: '5px' } : { padding: '5px' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <div style={{ flex: 1, padding: '5px', marginTop: -17 }}>
                                                                    <p>{productCurrency.fromCurrency !== undefined ? productCurrency.fromCurrency.name : ''}</p>
                                                                </div>
                                                                <div style={{ flex: 1, padding: '5px', marginTop: -17 }}>
                                                                    <div>
                                                                        {productCurrency.toCurrency != undefined &&
                                                                            <img width="24" alt="image not found."
                                                                                src={require('../asset/images/flags/' + productCurrency.toCurrency.name.toLowerCase() + '.png')}></img>}
                                                                        {" "}
                                                                        {productCurrency.toCurrency !== undefined ? productCurrency.toCurrency.name : ''}
                                                                    </div>
                                                                </div>
                                                                <div style={{ flex: 1, padding: '0px', marginTop: -13 }}>
                                                                    {/* <Form.Control type="number"
                                                                        placeholder="Multiplier"
                                                                        style={{ width: 150, height: 30 }}
                                                                        onChange={(event) => handleInputEditChange2(event, i, j, k)}
                                                                        value={productCurrency.multiplier != null && productCurrency.multiplier.toLocaleString(navigator.language, { minimumFractionDigits: 4 })}
                                                                        name="multiplier"
                                                                    /> */}
                                                                    {

                                                                        <div onClick={() => manageRatePairFee(productCurrency != null && productCurrency.id, productCurrency, product)}
                                                                            style={{ cursor: 'pointer', marginTop: -3 }}>
                                                                            {productCurrency.hasSetupCurrencyCharges ? <a className="btn-success" stuff-here style={{ padding: 5 }}>Edit</a> :
                                                                                <a className="btn-info" stuff-here style={{ padding: 5 }}>Add</a>}
                                                                        </div>



                                                                        // <i onClick={() => manageRatePairFee(productCurrency != null && productCurrency.id, productCurrency, product)} className="fas fa-dollar-sign" style={{ cursor: 'pointer', marginTop: -9, fontSize: 18 }}></i>
                                                                    }

                                                                </div>
                                                                {/* <div style={{ marginTop: -13, marginRight: 10 }}>{productCurrency.hasSetupCurrencyCharges ? <i class="fa fa-check" aria-hidden="true"></i> :
                                                                    <i class="fa fa-times-circle" aria-hidden="true"></i>}</div> */}



                                                            </div>
                                                            <hr style={{ marginTop: -14 }} />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div  >{showSpinner && <Spinner animation="border" size="lg" style={{ marginLeft: 500, marginTop: 150 }} />}</div>
                    )}
                </div>
            </div >
        );
    }
    //
    return <React.Fragment>
        <h3 id="tabelLabel"> {selectedCountry === null || selectedCountry === '' ?
            'Fee Structure' : 'Fee Structure : ' + selectedCountryName}
            {/* <button type="button" className="btn btn-primary float-right" onClick={handleShow} style={{ marginRight: 25 }}>Add Product</button> */}
        </h3>
        {createTable()}

        <Modal show={showAdd} onHide={handleClose} size={"lg"}>
            <Modal.Header closeButton>
                <Modal.Title>Add Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row md={12} style={{ marginTop: 8, marginBottom: 10 }}>
                        <Col md={4}> <Form.Label>Select Country</Form.Label></Col>
                        <Col md={7}> <Form.Control as="select" onChange={(event) => handleInputChange(event, -1)} name="groupId">
                            <option value="0">Select a Country</option>
                            {groupsforEdit.map(group => (
                                <option
                                    key={group.key}
                                    value={group.key}
                                >
                                    {group.value}
                                </option>
                            ))}
                        </Form.Control>
                        </Col>
                    </Row>
                    <Row>
                        <span style={{ color: "red", margin: 0 }}>{errors.groupId}</span>
                    </Row>
                    <Row md={12}>
                        <Col md={4}><Form.Label style={{ marginTop: 10 }}>Product Name</Form.Label></Col>
                        <Col md={7}> <Form.Control type="text"
                            placeholder="Enter product Name"
                            onChange={(event) => handleInputChange(event, -1)}
                            value={addProduct.name}
                            name="name"
                        />
                        </Col>
                    </Row >
                    <Row>
                        <Col><span style={{ color: "red", margin: 0 }}>{errors.name}</span></Col>
                    </Row>

                    <Row md={12} style={{ marginTop: 8 }}>
                        <Col md={4}> <Form.Label style={{ marginTop: 0 }}>Description</Form.Label></Col>
                        <Col md={7}> <Form.Control type="text"
                            placeholder="Description"
                            onChange={(event) => handleInputChange(event, -1)}
                            value={addProduct.description}
                            name="description"
                        />
                        </Col>
                    </Row>
                    <Row>
                        <Col> <span style={{ color: "red", marginTop: 0 }}>{errors.description}</span></Col>
                    </Row>

                    {/* <Row md={12} style={{ marginTop: 8, marginBottom: 10 }}>
            <Col md={4}> <Form.Label>Select Group Type</Form.Label></Col>
            <Col md={7}> <Form.Control as="select" onChange={(event) => handleInputChange(event, 1)} name="groupTypeId">
              <option value="0">Select a Group</option>
              <option value="1">Genric Group</option>
              <option value="2">Country Group</option>
            </Form.Control>
            </Col>
          </Row> */}



                </Container >
                <Container >
                    {addProduct.productCurrencies.map((pc, i) =>
                        <Container key={i} style={{ height: 350, width: 466, marginLeft: -17 }}>
                            <DualListBox
                                key={i}
                                name="toCurrencyId"
                                options={getCurrencyDualList}
                                selected={listBox}
                                onChange={handleOnChange}

                            />
                        </Container>
                    )}
                </Container>
            </Modal.Body >
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
          </Button>
                <Button variant="primary" onClick={saveProductData}>
                    Add
          </Button>
            </Modal.Footer>
        </Modal >


        <Modal show={showCurencyPair} onHide={handleCloseCurencyPair} size={"lg"}>
            <Modal.Header closeButton>
                <Modal.Title>

                    <div>
                        <h4>Country : {selectedCountryName}</h4>
                        <h4>Product : {curentProductName}</h4>
                        <div style={{ display: 'flex' }}>
                            <div >
                                {/* <p>{selectedproductCurrency.fromCurrency !== undefined ? selectedproductCurrency.fromCurrency.name : ''}</p> */}
                                <div>
                                    From : {" "}
                                    {selectedproductCurrency != null && selectedproductCurrency != undefined && selectedproductCurrency.fromCurrency != undefined &&
                                        <img width="24" alt="image not found."
                                            src={require('../asset/images/flags/' + selectedproductCurrency.fromCurrency.name.toLowerCase() + '.png')}></img>}
                                    {" "}
                                    {selectedproductCurrency != null && selectedproductCurrency != undefined && selectedproductCurrency.fromCurrency !== undefined ? selectedproductCurrency.fromCurrency.name : ''}
                                </div>
                            </div>
                            <div style={{ marginLeft: 20 }}>
                                <div>
                                    To : {" "}
                                    {selectedproductCurrency != null && selectedproductCurrency != undefined && selectedproductCurrency.toCurrency != undefined &&
                                        <img width="24" alt="image not found."
                                            src={require('../asset/images/flags/' + selectedproductCurrency.toCurrency.name.toLowerCase() + '.png')}></img>}
                                    {" "}
                                    {selectedproductCurrency != null && selectedproductCurrency != undefined && selectedproductCurrency.toCurrency !== undefined ? selectedproductCurrency.toCurrency.name : ''}
                                </div>
                            </div>

                        </div>
                        {/* <hr style={{ marginTop: -14 }} /> */}
                    </div>


                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div style={{ height: 332, overflowY: 'auto', overflowX: 'hidden' }}>
                    <Container>
                        <Row md={12}>
                            <Col md={3}>
                                <Form.Label style={{ fontWeight: 700 }}>From</Form.Label>
                            </Col>
                            <Col md={3}> <Form.Label style={{ fontWeight: 700 }}>To</Form.Label></Col>
                            <Col md={4}><Form.Label style={{ fontWeight: 700 }}>Fee</Form.Label></Col>
                        </Row>
                    </Container>
                    {curencyPairFees !== null && curencyPairFees !== undefined && curencyPairFees.map((c, i) =>
                        // <div key={i}>
                        //   test {i}
                        //   <Button variant="primary" style={{ marginRight: 20, height: 40 }} className="float-right" onClick={handleCurrencyPairAddClick}><i className='fas fa-plus'></i></Button>

                        // </div>
                        <Container key={i} style={{ marginTop: 10 }}>
                            <Row md={12}>
                                <Col md={3}>
                                    <Form.Control type="text"
                                        placeholder="0"
                                        onChange={(event) => handleInputChange(event, -1)}
                                        value={c.fromValue}
                                        name="zfrom"
                                    />
                                </Col>
                                <Col md={3}>  <Form.Control type="number"
                                    placeholder="To"
                                    onChange={(event) => handleCuurencyPairEditChange(event, 1, i)}
                                    value={c.toValue}
                                    name="zto"
                                /></Col>
                                <Col md={3}>
                                    <Form.Control type="number"
                                        placeholder="Fee"
                                        onChange={(event) => handleCuurencyPairEditChange(event, 2, i)}
                                        value={c.fee}
                                        name="zFee"
                                    /></Col>
                                <Col md={3}>   {
                                    i == curencyPairFees.length - 1 &&
                                    <div>
                                        <Button variant="outline-primary" style={{ marginRight: 2, height: 40 }} onClick={handleCurrencyPairAddClick}><i className='fas fa-plus'></i></Button>
                                        {curencyPairFees.length > 1 && <Button variant="outline-danger" style={{ height: 40 }} onClick={handleCurrencyPairDeleteClick}><i className='fas fa-trash'></i></Button>}

                                    </div>

                                }

                                </Col>
                            </Row>
                        </Container>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseCurencyPair}>
                    Close
          </Button>

                <Button variant="primary" onClick={SaveCloseCurencyPair}>
                    Save
          </Button>


            </Modal.Footer>
        </Modal>



        <Modal show={showEdit} onHide={handleCloseEdit} size={"lg"} >
            <Modal.Header closeButton>
                <Modal.Title>Product Edit</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Container>
                    <Row md={12}>
                        <Col md={4}><Form.Label style={{ marginTop: 10 }}>Product Name</Form.Label></Col>
                        <Col md={7}>  <Form.Control type="text"
                            placeholder="Enter product Name"
                            onChange={(event) => handleInputEditChange(event, -1)}
                            value={editProduct.name}
                            name="name"
                        />
                        </Col>
                    </Row>

                    <Row md={12} style={{ marginTop: 8 }}>
                        <Col md={4}> <Form.Label style={{ marginTop: 0 }}>Description</Form.Label></Col>
                        <Col md={7}> <Form.Control type="text"
                            placeholder="Description"
                            onChange={(event) => handleInputEditChange(event, -1)}
                            value={editProduct.description}
                            name="description"
                        />
                        </Col>
                    </Row>

                    {/* <Row md={12} style={{ marginTop: 8, marginBottom: 10 }}>
            <Col md={4}> <Form.Label>Select Group</Form.Label></Col>
            <Col md={7}><Form.Control as="select" disabled onChange={(event) => handleInputEditChange(event, -1)} name="groupId" value={editProduct.groupId}>
              <option value="0">Select a Group</option>
              {groupsforEdit.map(group => (
                <option
                  key={group.key}
                  value={group.key}
                >
                  {group.value}
                </option>
              ))}
            </Form.Control>
            </Col>
          </Row> */}
                </Container>
                <hr />
                <div style={{ height: 332, overflowY: 'auto', overflowX: 'hidden' }}>
                    <Container>
                        <Row md={12}>
                            <Col md={3}>
                                <Form.Label style={{ fontWeight: 700 }}>From</Form.Label>
                            </Col>
                            <Col md={3}> <Form.Label style={{ fontWeight: 700 }}>To</Form.Label></Col>
                            <Col md={3}><Form.Label style={{ fontWeight: 700 }}>Multiplier</Form.Label></Col>
                        </Row>
                    </Container>
                    {editProduct.productCurrencies.map((editPc, i) =>
                        <div key={i}>
                            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div>
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        {/* <Form.Label>From</Form.Label> */}
                                        <Form.Control as="select" onChange={(event) => handleInputEditChange(event, editPc.index, 1)} name="fromCurrencyId" value={editPc.fromCurrencyId} disabled={true}>
                                            {currencies.map(currency => (
                                                <option
                                                    key={currency.key}
                                                    value={currency.key}
                                                >
                                                    {currency.value}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                <div>
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        {/* <Form.Label>To</Form.Label> */}
                                        <Form.Control as="select" onChange={(event) => handleInputEditChange(event, editPc.index, 1)} name="toCurrencyId" value={editPc.toCurrencyId}>
                                            <option value="0">Select ..</option>
                                            {currencies.map(currency => (
                                                <option
                                                    key={currency.key}
                                                    value={currency.key}
                                                >
                                                    {currency.value}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                <div>
                                    {/* <Form.Label>Multiplier</Form.Label> */}
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control type="number"
                                            placeholder="Multiplier"
                                            onChange={(event) => handleInputEditChange(event, editPc.index, 2)}
                                            value={editPc.multiplier}
                                            name="multiplier"
                                        />
                                    </Form.Group>
                                </div>
                                <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                    {editProduct.productCurrencies.length !== 1 && <Button variant="outline-danger" style={{ marginLeft: 20, height: 40 }} onClick={() => handleEditRemoveClick(editPc.index)}><i class='fas fa-trash'></i></Button>}
                                </div>
                            </div>
                            <div>
                                {editProduct.productCurrencies.length - 1 === i && <Button variant="outline-primary" style={{ marginLeft: 20, height: 40 }} className="float-right" onClick={handleEditAddClick}><i class='fas fa-plus'></i></Button>}
                            </div>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseEdit}>
                    Close
          </Button>
                <Button variant="primary" onClick={Editproduct}>
                    Edit
          </Button>
            </Modal.Footer>
        </Modal>

    </React.Fragment >;

}

