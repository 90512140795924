import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import Counter from './components/Counter';
import FetchData from './components/FetchData';

import './custom.css'
import Group from './components/Group';
import Product from './components/Product';
import Report from './components/Report';
import ProductCharges from './components/ProductCharges';

export default () => (
    <Layout>
        {/* <Route exact path='/' component={Home} /> */}
       
        <Route exact path='/' component={Product} />
        <Route exact path='/productcharges' component={ProductCharges} />
        <Route exact path='/group' component={Group} />
        <Route exact path='/report' component={Report} />
        <Route exact path='/reportview' component={Report} />
       
    </Layout>
    
);
